// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/background.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".game-container {\n    position: 'relative';\n    width: 100%;\n    height: 100vh;\n    outline: 0px;\n    border: 2px solid #ffffff;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat-x left center;\n    display: inline-block;\n    animation: displace 10s linear infinite;\n    background-size: 100% 100%;\n}\n\n.title{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #ffffff;\n    font: 700 50px Poppins;\n    margin-top: 50px;\n    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;\n}\n  \n  @keyframes displace {\n  \n    from {\n      background-position: 0 center;\n    }\n  \n    to {\n      background-position: 1920px center;\n    }\n}", ""]);
// Exports
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../data/tiles/Tilesets/rock.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../data/tiles/Tilesets/three.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../data/tiles/Tilesets/chest.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../data/maps/1/mapa960x640(32px).png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".tile {\n    z-index: 0;\n    display: inline-flex;\n}\n \n.tile.rock {\n    z-index: 1;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.tile.tree {\n    z-index: 1;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");    \n}\n\n.tile.chest {\n    z-index: 1;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")\n}\n\n.map{\n    /**background-image: url('../../data/maps/1/mapa1.png');*/\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    width: 800px;\n    height: 400px;\n}\n", ""]);
// Exports
module.exports = exports;
